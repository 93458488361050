body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    /* iphone X 兼容性布局参考 top 88px bottom 34px */
    /*padding-top: constant(safe-area-inset-top);*/
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
}


/* iphone X 兼容性布局参考 */

@media only screen and (-webkit-min-device-pixel-ratio: 3),
only screen and ( min--moz-device-pixel-ratio: 3),
only screen and (  -o-min-device-pixel-ratio: 3/1),
only screen and (  min-device-pixel-ratio: 3),
only screen and (    min-resolution: 458dpi),
only screen and (    min-resolution: 3dppx) {
    .ipx {
        /* iphone X 兼容性布局参考 34px */
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
      /*padding-bottom: 89px;*/
    }
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-item {
  flex: 1 1;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.z-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
}
