.photo {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.oper-extra {
  padding: 0 16px;
  display: flex;
  justify-content: space-around;
}
.oper-extra .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
}
.oper-extra .am-checkbox.am-checkbox-checked .am-checkbox-inner:after {
  border-color: #108ee9;
}
.oper-extra .am-checkbox-inner {
  width: 16px;
  height: 16px;
  border: none;
}
.oper-extra .am-checkbox-inner:after {
  width: 5px;
  height: 10px;
  right: 3px;
  top: 0;
}
.checkbox-pretty .am-checkbox{
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 2px;
}
.oper-extra-txt {
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #C2C2C2;
}

.imgload-bg {
  width: 168px;
  background-color: #FBFBFB;
  border-radius: 4px;
  position: relative;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  overflow: hidden;
}

.block {
  /*width: 150px;*/
  height: 171px;
  background: url('../../../../static/images/block-bg.png') center center no-repeat no-repeat;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 2px 10px;
  margin: 10px 0;
  position: relative;
}

.block-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 2px 10px;
}

.camera-flag {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 171px;
  background: url('../../../../static/images/camera-flag.png') center center no-repeat no-repeat;
  z-index: 1;
}

.sign-flag {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 171px;
  background: url('../../../../static/images/sign-flag.png') center center no-repeat no-repeat;
  z-index: 1;
}

.center-img {
  display: block;
  flex: 1;
  height: 158px;
  margin: 10px auto;
  max-width: 140px;
  max-height: 150px;
}

.bg-txt {
  font-size: 12px;
  color: #1B91FF;
  font-weight: 500;
  margin: 0 0 10px 0;
}
.oper-area {
  width: 168px;
  display: flex;
  justify-content: space-around;
  margin: 12px 0;
}

.oper-area .oper-btn {
  font-size: 14px;
  width: 67px;
  height: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.oper-area .oper-zoom {
  color: #1B91FF;
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  background: #FFFFFF;
  border: 1px solid #1890FF;
  overflow: hidden;
  position: relative;
}

.oper-area .oper-del {
  color: #fff;
  background: #1B91FF;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 2px;
}

.oper-area .oper-img {
  display: inline-block;
  width: 18px;
  height: 18px;
}

.oper-area .oper-txt {
}

