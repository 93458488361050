.identity-info {
  width: 100%;
}

.city-header {
  height: 200px;
  background: linear-gradient(184.01deg, #FFDD4D 1.45%, #FCBE03 95.03%);

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    background: url(../../../static/images/signup.png) no-repeat center center;
    background-size: cover;
  }

  position: relative;

  >h3 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 40px;

    color: #AF6009;

    bottom: 42px;
    left: 16px;
    position: absolute;

  }
}

.city-list {
  background-color: #fff;
  border-radius: 15px 15px 0 0;
  position: relative;
  top: -30px;
  overflow: hidden;
  padding-top: 20px;
}

.city-box {
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 99;
  top: 0;
  height: 100%;

  .btns {
    position: absolute;
    bottom: 38px;
    width: 100%;
  }

  :global {

    .am-list-body {

      &:before,
      &:after {
        background: #e8e8e8 !important;
      }
    }

    .am-list-line {
      height: 56px;

      .am-list-content {
        font-size: 16px;
        color: #5C5C5C;
      }
    }
  }
}

.btns {
  padding: 0 16px;
  box-sizing: border-box;

  button {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    background: linear-gradient(283.6deg, #FCBE03 9.74%, #FFDD4D 88.61%);
    border-radius: 5px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-family: PingFang SC;
    font-size: 16px;
    color: #5C5C5C;
    position: relative;

    &[disabled] {
      opacity: .5;
    }

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.ps-box {
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;

  .psyq {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    border-bottom: 10px solid #F8F8F8;

    img {
      max-width: 124px;
      max-height: 173px;
      margin-right: 25px;
      width: 26%;
    }

    >div {
      line-height: 24px;

      strong {
        font-weight: bold;
      }

      ol {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  .pssm {
    padding: 16px;

    img {
      max-width: 100%;
    }
  }

  .tips {
    line-height: 24px;
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;

    span {
      color: red;
    }
  }

  .btns {
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        background: url(../../../static/images/icon-ps.png) no-repeat;
        display: inline-block;
        width: 18px;
        height: 16px;
        background-size: cover;
        margin-right: 10px;
      }
    }
  }
}

.ps-confirm {
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  padding: 0 16px;

  img {
    max-height: 80%;
    max-width: 100%;
  }

  .btns {
    display: flex;
    margin: 50px 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    button {
      width: 48%;
      border-radius: 40px;

      &:first-child {
        background: #000;
        color: #FCBE03;
        border: 1px solid #FCBE03;
        box-sizing: border-box;
      }
    }
  }
}

.ps-result {
  height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  padding-top: 120px;

  h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 8px;
  }

  p {
    color: #999;
    line-height: 22px;
    margin: 0;
  }

  .btns {
    width: 190px;
    margin: 40px auto 0;

    button {
      border-radius: 40px;
    }
  }
}

.ps-preview {
  height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  img {
    max-width: 100%;
  }

  .btns {
    width: 70%;
    margin: 20px auto 0;
  }
}

.ps-yzm {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .7);
  }

  .yzm-content {
    background: #fff;
    border-radius: 5px;
    padding: 20px 40px 0;
    box-shadow: 0 2px 3px rgba(255, 255, 255, .4);
    text-align: center;
    position: relative;
    min-width: 200px;

    h3 {
      margin: 0;
      font-weight: bold;
    }

    img {
      display: block;
      margin: 6px auto;
    }

    input {
      border: 1px solid #FCBE03;
      margin: 12px 0 18px;
      height: 36px;
      line-height: 36px;
      text-align: center;
    }

    .btns {
      padding: 0;
      margin: 0 -40px;

      button {
        height: 38px;
        line-height: 38px;
        border-radius: 0 0 5px 5px;
      }
    }
  }
}


.list-box {
  background: #FFFFFF;
  border-radius: 5px;
  margin: 16px;
  padding: 15px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      border: 1px solid #5C5C5C;
      box-sizing: border-box;
      color: #5C5C5C;
      border-radius: 5px;
      height: 24px;
      line-height: 24px;
      padding: 0 6px;
      text-align: center;
      margin-left: 6px;
    }
  }

  p {
    span {
      color: #999999;
    }
  }

  h4 {
    font-weight: normal;
    margin: 0;
    margin-top: 15px;
    font-size: 15px;
    color: #5C5C5C;
    border-top: 1px solid #f2f2f2;
    padding-top: 15px;

    :global {
      strong {
        color: #999;

        &.underReview {
          color: #C08543;
        }

        &.auditSucceeded {
          color: #5392E6;
        }

        &.auditFailed,
        &.otherServiceSide {
          color: red;
        }
      }
    }
  }

  h5 {
    font-size: 15px;
    font-weight: normal;
    margin: 0;
    margin-top: 10px;
    color: #5C5C5C;
  }
}
