/*.am-steps-label-vertical .am-steps-item {*/
/*    overflow: hidden*/
/*}*/

.app {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.steps-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
}

.text {
    color: #999;
    font-size: 14px;
    margin-bottom: 25px;
}

.interval {
    height: 12px;
    background-color: #F0F0F0;
}

.select .am-list-item .am-list-line .am-list-extra {
    color: #333333;
}

.relative {
    overflow: auto;
  width: 100%;
}
