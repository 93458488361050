.ax-tab-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  height: 100%;
}

.ax-tab-wrapper .tabs-content {
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  height: 100%;
  overflow: auto;
}

.ax-tab-wrapper .am-tabs-default-bar-tab {
  height: 56px !important;
  line-height: 56px !important;
}

.ax-tab-wrapper .interval {
  height: 12px;
  background-color: #F0F0F0;
}

.detail-box {
  padding: 0;
  margin: 0;
  padding: 0 16px;
}

.detail-box li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F8F8F8;
  padding: 18px 0;
  list-style: none;
  background-color: #fff;
}

.detail-box li.hr {
  background: #F8F8F8;
  height: 12px;
  padding: 0;
  margin: 0 -16px
}

.detail-box li span:first-child {
  color: #666;
}
