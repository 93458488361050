.tabsMain {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.requirements {
    width: 100%;
    height: 130px;
    margin-top: 20px;
}

.sample-text {
    text-align: right;
    margin: 2px 20px;
    font-size: 12px;
    color: #1B91FF;
}

.sample-text img {
    position: relative;
    top: 3px;
}

.modal-box img {
    width: 240px;
}

.red {
    color: #F95B5A;
}

.sample-img {
    width: 16px;
    height: 16px;
}

.pictureBtns {
  width: 100%;
}
