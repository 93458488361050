.conditionItem {
  display: flex;
  align-items: center;
}

.title {
  width: 50px;
  text-align: left;
}

.condition {
  margin-left: 30px;
  line-height: 16px;
}

.schoolName {
  color: rgb(102,102,102);
  display: inline-block;
  line-height: 16px;
  vertical-align: super;
}
