.btns {
  padding: 0 16px;
  box-sizing: border-box;

  button {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    background: linear-gradient(283.6deg, #FCBE03 9.74%, #FFDD4D 88.61%);
    border-radius: 5px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-family: PingFang SC;
    font-size: 16px;
    color: #5C5C5C;
    position: relative;
    white-space: nowrap;

    &[disabled] {
      opacity: .5;
    }

    input {
      width: 100%;
    }
  }
}

button.ljpz {
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    background: url(../../../../static/images/icon-ps.png) no-repeat;
    display: inline-block;
    width: 18px;
    height: 16px;
    background-size: cover;
    margin-right: 10px;
  }
}

.modal-box {
  width: 86%;
}

.yzm-content {
  background: #fff;
  border-radius: 5px;
  padding: 20px 40px 0;
  box-shadow: 0 2px 3px rgba(255, 255, 255, .4);
  text-align: center;
  position: relative;
  min-width: 200px;
  box-sizing: border-box;

  h3 {
    margin: 0;
    font-weight: bold;
  }

  img {
    display: block;
    margin: 6px auto;
  }

  input {
    border: 1px solid #FCBE03;
    margin: 12px 0 18px;
    height: 36px;
    line-height: 36px;
    text-align: center;
  }

  .btns {
    padding: 0;
    margin: 0 -40px;

    button {
      height: 38px;
      line-height: 38px;
      border-radius: 0 0 5px 5px;
    }
  }
}

.pay-box {
  padding: 20px 16px 0;

  img {
    max-width: 100%;
  }

  .psyq {
    line-height: 24px;
    text-align: left;

    h3 {
      font-size: 15px;
    }

    ol {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .pssm {
    padding: 16px;
    margin-top: 50px;

    img {
      max-width: 100%;
    }
  }

  .tips {
    line-height: 24px;
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;

    span {
      color: red;
    }
  }
}
