.full-wrap {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateZ(1px);
  background-color: #fff;
}

.full-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pre-title {
  height: 47px;
  line-height: 47px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.preview {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #C4C4C4;
  overflow: hidden;
}

.btns-box .am-flexbox .am-flexbox-item {
  margin: 0;
}


