.custom-icon {
    width: 24px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 16px;
    border: 1px solid #8C8C8C;
    background-color: #fff;
    color: #8C8C8C;
    font-family: PingFang SC;
}

.custom-icon-ack {
    width: 24px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 16px;
    border: 1px solid #1890FF;
    background-color: #1890FF;
    color: #fff;
    font-family: PingFang SC;
}

.vertical-step .am-steps-item-tail {
    padding: 22px 0 0 0;
    top: 2px;
}

.vertical-step .am-steps-item-content {
    margin-top: 0;
}

.vertical-step .am-steps-item-tail:after {
    background-color: #1890FF !important;
}

.label {
    margin-top: 6px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}

.step {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    font-family: PingFang SC;
}

.text {
    color: #999;
    font-size: 14px;
    margin-bottom: 25px;
}
