.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #C4C4C4;
  overflow: hidden;
  direction: ltr;
  font-size: 0;
  line-height: 0;
  user-select: none;
  position: relative;
}

.bg {
  //background: url("./cropper-bg.png");
  background: #000;
}

.boxBg {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
}

.center::before {
  content: " ";
  display: block;
  width: 20px;
  height: 2px;
  background-color: rgba(222, 222, 222, 0.7);
  position: absolute;
  top: 29px;
  left: 20px;
  z-index: 5;
}
.center::after {
  content: " ";
  display: block;
  width: 2px;
  height: 20px;
  background-color: rgba(222, 222, 222, 0.7);
  position: absolute;
  top: 20px;
  left: 29px;
  z-index: 5;
}

.boxImg {
  position: absolute;
  z-index: 4;
}

.imgHidden {
  display: none;
}

.wrapbox {
  overflow: hidden;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  /* 给手势操作留点空间 */
}

.cropperSVG {
  overflow: hidden;
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  border: 1px solid red;
}

.test {
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 0;
  color: white;
  width: 200px;
  line-height: 30px;
  word-break: break-all;
  font-size: 12px;
}
