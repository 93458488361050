.timeSetting {
  display: flex;
  align-items: center;
}
.title {
  width: 50px;
  text-align: right;
}

.items {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeItem {
  padding: 6px 16px;
  background: rgb(246,246,246);
  border-radius: 20px;
  color: rgb(102,102,102);
  margin: 0 6px;
}

.timeItem.selected {
  color: rgb(147,148,241);
  background: rgb(250,249,255);
}
