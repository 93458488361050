.btn-next {
  width: 100%;
  color: #fff;
  font-size: 18px;
  height: 47px;
  /* 按钮渐变 */
  background: linear-gradient(180deg, rgba(53, 57, 85, 0.8) 0%, #353955 100%);
  border: none;
  position: relative;
  overflow: hidden;
  line-height: 47px;
}

.btn-last {
  width: 100%;
  height: 47px;
  color: #fff;
  font-size: 18px;
  background: #FFFFFF;
  color: #353955;
  box-sizing: border-box;
  border: 1px solid #E3E3E3;
}

.btn-camera {
  overflow: hidden;
  justify-content: center;
  position: relative;
  text-align: center;
  line-height: 47px;
}

.btns-box .am-flexbox .am-flexbox-item {
  margin: 0;
}
