.list {
    height: 56px;
    line-height: 56px;
}

.sex-name {
    display: inline-block;
    width: 85px;
    margin: 0 0 0 15px;
    font-size: 17px;
}

.radio {
    position: relative;
    top: 2px;
    margin: 0 8px;
    width: 16px;
    height: 16px;
    line-height: 56px;
    color: #f60;
}

.man {
    margin-right: 20px;
}

.red {
    color: #F95B5A;
}

.text-name-class .from-label {
    font-size: 12px;
}

.left {
    float: left;
    height: 56px;
}

.right {
    float: right;
    margin-right: 20px;
}

.clearfix:after {
    content: "020";
    display: block;
    clear: both;
    visibility: hidden;
}

.time-icon :global(.am-list-item .am-list-line .am-list-arrow) {
    width: 18px;
    height: 18px;
    background-image: url(../../static/images/timeicon.svg);
}

.time-icon .am-list-item .am-list-line .am-list-extra {
    color: #333;
}

.flex-box-radio {
    display: flex;
    justify-content: space-between;
}

.right-radio {
    margin: 0 14px;
    font-size: 16px;
}

.right-radio span {
    margin: 0 6px;
}

.select :global(.am-list-arrow) {
    background-image: url(../../static/images/selecticon.svg) !important;
}

.code {
    font-size: 16px;
    color: #1890FF;
}

.identity-info :global(.am-list-item.am-input-item) {
    height: 56px;
}

.identity-info :global(.am-list-item) {
    height: 56px;
}

.identity-info :global(.am-list-item.am-textarea-item) {
    height: 90px;
}

.identity-info :global(.am-list-extra) {
    min-width: 41vw;
    overflow: scroll !important;
    text-overflow: initial !important;
    white-space: inherit !important;
}