.cardInfo {
    border-radius: 4px;
    border: 1px solid #E5EAEF;
    box-sizing: border-box;
    padding: 16px 12px 12px;
    height: 180px;
    position: relative;
    margin-bottom: 16px;
}

.cardInfo .text-data {
    height: 150px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    color: hsl(0, 0%, 40%);
}

.cardInfo .form-lable-top {
    height: 22px;
    margin-bottom: 12px;
    font-size: 16px;
}

.cardInfo .form-label-sex {
    width: 42px;
    margin-left: 5px;
    text-align: center;
    line-height: 17px;
    font-size: 14px;
    position: relative;
    bottom: 2px;
}

.cardInfo .form-lable-data {
    margin-bottom: 8px;
    font-size: 12px;
    height: 16px;
}


/* 详情按钮 */

.cardInfo .button-group {
    position: absolute;
    top: 16px;
    right: 10px;
}

.button-group .am-button {
    /* height: 40px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 2px; */
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 14px;
}

.button-group .am-button:before {
    /* width: 100% !important;
  height: 100% !important; */
    transform: scale(0.498) !important;
}

.button-group .am-button span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: normal;
}

.button-group .detail-button {
    /* width: 44px;
  height: 24px !important;
  border-radius: 2px;
  text-align: center; */
    margin-bottom: 20px;
}

.form-label-name {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
    height: 17px;
    display: inline-block;
}


/* .button-group  .am-button-small {
  padding: 0px !important;
  line-height: 25px !important;
} */