.outer-box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
}

.query-box {
    padding: 22px 16px 16px;
    /* 搜索框吸顶 */
    position: sticky;
    position: -webkit-sticky;
    /*position: -moz-sticky;*/
    /*position: -ms-sticky;*/
    top: 0;
    z-index: 888;
    background-color: #fff;
}


/* 搜索框 */

.search-form {
    width: 100%;
    height: 40px;
    background: #FBFBFB;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0px 25px 0px 9px;
    background-repeat: no-repeat
}


/* 搜索框图标 */

.icon-search {
    /* 路径 */
    display: inline-block;
    height: 16px;
    width: 16px;
    background-image: url(../../static/images/icon-search.png);
    background-repeat: no-repeat;
    position: absolute;
    right: 24px;
    top: 34px;
    z-index: 2;
    background-size: cover;
}

.data-box {
    padding: 0 16px;
    position: relative;
}

.query-box .am-search {
    border-bottom: 1px solid #ddd;
    background: #FBFBFB;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 2px;
}


/* 更改 placeholder 颜色*/

.outer-box input::-webkit-input-placeholder {
    color: #D7D7D7;
}

.outer-box input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #D7D7D7;
}

.outer-box input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #D7D7D7;
}

.outer-box input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #D7D7D7;
}


/* 渲染的底部 */

.renderFooter {
    text-align: center;
}


/* 回到顶部box */

.back-top {
    position: fixed;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5;
    left: 50px;
    bottom: 18vh;
    z-index: 10;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.outer-box .back-top-content {
    width: 40px;
    height: 40px;
    color: #000;
    text-align: center;
    background-color: rgba(0, 0, 0, .45);
    /* background-color: #fff; */
    border-radius: 20px;
}


/* 回到顶部图标 */

.back-icon {
    width: 14px;
    height: 16px;
    margin: 9px auto;
}

.data-box .am-list-body::before {
    height: 0 !important;
}

.data-box .am-list-body::after {
    height: 0 !important;
}

.img-style {
    margin-top: 5px;
    width: 30px;
    height: 30px;
}