.card {
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #ccc;
}
.title {
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin: 16px 0;
}


.pad-20 {
  padding: 0 10px;
}
.school-name {
  margin: 0 20px;
  color: rgb(102,102,102);
}
.condItem {
  margin: 10px 0;
  color: rgb(102,102,102);
}

.currTime {
  text-align: center;
}
