.requirements {
  color: #666666;
  /* position: absolute;
  top: 20px;
  right: 20px; */
  margin: 20px 20px 0 20px;
}
.requirements.zjz-box{
  margin: 20px 16px 0 16px;
}
.require-content {
  float: right;
  /* margin-right: 18px; */
}
.require-content::after {
  content: " ";
  display: none;
  clear: both;
}

.txt-main {
  font-size: 14px;
  color: #666666;
}
.txt-content {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}
.requirements.zjz-box .txt-item{
  max-width: inherit;
}
.txt-item {
  margin: 1px 0 8px 0;
  max-width: 200px;
  display: flex;
  align-items: center;
}
.txt-item::before {
  content: "·";
  color: #1B91FF;
  margin-right: 10px;
}
.sample-text {
  color: #1B91FF;
  font-size: 12px;
  text-align: right;
}
.sample-text::after {
  content: "?";
  display: inline-block;
  font-size: 11px;
  border-radius: 6px;
  width: 11px;
  height: 11px;
  border: 1px solid #1B91FF;
  text-align: center;
}
.rmc-tabs .rmc-tabs-tab-bar-left .rmc-tabs-tab-bar-tab {
  max-height: 56px;
  font-size: 14px;
  padding: 0 16px;
  justify-content: flex-start;
  color: #999;
}

.rmc-tabs .rmc-tabs-tab-bar-tab.rmc-tabs-tab-bar-tab-active {
  color: #000;
  background-color: #fff;
  border-radius: 8px 0px 0px 8px;
}

.rmc-tabs .rmc-tabs-tab-bar-tab.rmc-tabs-tab-bar-tab-active::before {
  content: " ";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  position: absolute;
  top: -8px;
  right: 0;
}

.rmc-tabs .rmc-tabs-tab-bar-tab.rmc-tabs-tab-bar-tab-active::after {
  content: " ";
  display: block;
  width: 8px;
  height: 8px;
  background-color: rgba(240,240,240,1);
  border-radius: 0 0 8px 0;
  position: absolute;
  top: -8px;
  right: 0;
}

.rmc-tabs .rmc-tabs-tab-bar-tab.rmc-tabs-tab-bar-tab-actpuls::before {
  content: " ";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.rmc-tabs .rmc-tabs-tab-bar-tab.rmc-tabs-tab-bar-tab-actpuls::after {
  content: " ";
  display: block;
  width: 8px;
  height: 8px;
  background-color: rgba(240,240,240,1);
  border-radius: 0 8px 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.z-tab-title {
  font-size: 14px;
  display: flex;

}

.z-tab-title::before {
  content: " ";
  display: inline;
  min-width: 3px;
  height: 100%;
}

.z-tab-title.required::before {
  content: "*";
  color: red;
  vertical-align: middle;
}

.tab-content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.exImg {
  width: 240px;
  max-width: 100%;
}

@media screen and (orientation: portrait) and (max-height: 666px) {
  .tab-content .imgload-container {
    min-height: 300px;
  }
}

.imgload-container {
  min-height: 390px;
}

.pre-image {
  max-width: 100%;
  max-height: 100%;
}
